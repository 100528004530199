import React from "react";
import get from 'lodash/get';
import cx from 'classnames';
import { browserHistory, Link } from "react-router";
import { formatNumber, formatCurrency, sumCoin, formatCoin } from '../../common/helpers/utils';
import { transactionsService } from '../../common/services/transaction';
import { stakeService } from '../../common/services/stake';
import { blocksService } from '../../common/services/block';
import PandoChart from '../../common/components/chart';
import BigNumber from 'bignumber.js';
import LoadingPanel from "../../common/components/loading-panel";


export default class TokenDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      blockNum: 0,
      txnNum: 0,
      totalStaked: 0,
      holders: [],
      percentage: [],
      txTs: [],
      txNumber: [],
      nodeNum: 0,
      rametronStake: 0,
      metatronStake: 0,
      zetatronStake: 0,
      lbankPrince: 0,
      marketCap: 0,
      CirculatingSupply: 0,
      totalWallet: 0,
      TotalSupply: 0,
      totalcountryCount: [],
      loading: true,
      totalburnfee: 0,
      totaltransaction: 0,
      totalexchanges: 2,
      totalexhcnageusers: 1824,
      totaldapps: 9,
      latestPrice: '',
      blochheight: '',
      totalCountTrans: '',
      totalblocknum:0
    };
    this.searchInput = React.createRef();
    this.searchType = React.createRef();
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    //this.marketCap();
    this.setState({ loading: true })
    this.lbank();
    //  this.circulatingSupply();
    this.getTransactionNumber();
    this.getBlockNumber();
    this.getTransactionHistory();
    this.getTotalStaked();
    this.getAllStakes();
    this.totalWalletCount();
    this.totalWalletSupply()
    this.totalcountryCount();
    this.getfeeetc();
    this.getTotalBlocks()

  }
  getTotalBlocks()
  {
    blocksService.getBlocksByPage(1, 1)
      .then(res => {
  this.setState({totalblocknum:res.data.body[0].height})

      }).catch(err => {

      })
  }
  getfeeetc() {
    stakeService.getTotalFees()
      .then(res => {
        // const txHistory = get(res, 'data.body.data');

        this.setState({ totalburnfee: res.data.response[0].TotalFees })
        // this.setState({ txTs, txNumber })
        stakeService.getTotal_count()
          .then(res => {
            this.setState({ totaltransaction: res.data.response[0].totalCount })
            // const txHistory = get(res, 'data.body.data');
            // this.setState({ txTs, txNumber })
          })
      })
      .catch(err => {

      });

  }
  handleSearch() {
    const value = this.searchInput.value;
    switch (this.searchType.value) {
      case "address":
        if (value !== "") {
          browserHistory.push(`/account/${value}?tx=transfer`);
          this.searchInput.value = "";
        }
        break;
      case "block":
        browserHistory.push(`/blocks/${value}`);
        this.searchInput.value = "";
        break;
      case "transaction":
        browserHistory.push(`/txs/${value}`);
        this.searchInput.value = "";
        break;
      default:
        break;
    }
  }

  clearSearchBox = () => {
    if (this.searchInput.value) {
      this.searchInput.value = "";
    }
  };
  handleEnterKey(e) {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  }

  getTransactionHistory() {
    transactionsService.getTransactionHistory()
      .then(res => {
        const txHistory = get(res, 'data.body.data');
        let txTs = [];
        let txNumber = []
        txHistory.sort((a, b) => a.timestamp - b.timestamp).forEach(info => {
          txTs.push(new Date(info.timestamp * 1000));
          txNumber.push(info.number);
        })
        this.setState({ txTs, txNumber })
      })
      .catch(err => {

      });
  }
  getAllStakes() {
    stakeService.getAllStake()
      .then(res => {
        let metatronStakeTotal = 0;
        let zetatronStakeTotal = 0;
        let rametronStakeTotal = 0;

        const stakeList = get(res, 'data.body')
        const metatronStake = stakeList.filter((val) => val.type === 'gcp')
        const zetatronStake = stakeList.filter((val) => val.type === 'vcp')
        const rametronStake = stakeList.filter((val) => val.type === 'rametronenterprisep')
        metatronStake.map((val) => {
          metatronStakeTotal = Number(metatronStakeTotal + Number(val.amount))
        })
        zetatronStake.map((val) => {
          zetatronStakeTotal = Number(zetatronStakeTotal + Number(val.amount))
        })
        rametronStake.map((val) => {
          rametronStakeTotal = Number(rametronStakeTotal + Number(val.amount))
        })
        this.setState({
          metatronStake: metatronStakeTotal
        })
        this.setState({
          zetatronStake: zetatronStakeTotal
        })
        this.setState({
          rametronStake: rametronStakeTotal
        })


        let sum = stakeList.reduce((sum, info) => { return sumCoin(sum, info.amount) }, 0);
        let newObj = stakeList.reduce((map, obj) => {
          if (!map[obj.holder]) map[obj.holder] = 0;
          map[obj.holder] = sumCoin(map[obj.holder], obj.amount).toFixed()
          return map;
        }, {});
        let topStakes = Array.from(Object.keys(newObj), key => {
          return { 'holder': key, 'amount': newObj[key] }
        }).sort((a, b) => {
          return b.amount - a.amount
        }).slice(0, 8)
        let sumPercent = 0;
        let objList = topStakes.map(stake => {
          let obj = {};
          obj.holder = stake.holder;
          obj.percentage = new BigNumber(stake.amount).dividedBy(sum / 100).toFixed(6);
          sumPercent += obj.percentage - '0';
          return obj;
        }).concat({ holder: 'Rest Nodes', 'percentage': (100 - sumPercent).toFixed(6) })
        this.setState({
          holders: objList.map(obj => { return obj.holder }),
          percentage: objList.map(obj => { return (obj.percentage - '0') }),
          loading: false
        });
      })
      .catch(err => {

      });
  }
  getTransactionNumber() {
    transactionsService.getTotalTransactionNumber(24)
      .then(res => {
        const txnNum = get(res, 'data.body.total_num_tx');
        this.setState({ txnNum })
      })
      .catch(err => {

      });
  }
  getBlockNumber() {
    blocksService.getTotalBlockNumber(24)
      .then(res => {
        const blockNum = get(res, 'data.body.total_num_block');
        this.setState({ blockNum })
      })
      .catch(err => {

      });
  }

  totalWalletCount() {
    stakeService.totalWalletCount()
      .then(res => {

        this.setState({ totalWallet: res.totalwallet })
      })
      .catch(err => {

      });
  }
  totalWalletSupply() {
    stakeService.totalWalletSupply()
      .then(res => {

        this.setState({ TotalSupply: res.totalSupply })
      })
      .catch(err => {

      });
  }

  getTotalStaked() {
    const { type } = this.props;
    stakeService.getTotalStake()
      .then(res => {
        const stake = get(res, 'data.body')

        this.setState({ totalStaked: (Number(stake.totalAmount / 1000000000000000000)), nodeNum: stake.totalNodes });
      })

  }


  lbank() {
    stakeService.lbank().then((data) => {
      this.setState({
        lbankPrince: data.lBankPrice[0].latestPrice
      })

    })
  }
  marketCap() {
    stakeService.marketCap().then((data) => {
      this.setState({
        marketCap: Math.floor(data.marketCap428)
      })
    })
  }
  circulatingSupply() {
    stakeService.circulatingSupply().then((data) => {
      this.setState({
        CirculatingSupply: Number(data.CirculatingSupply)
      })
    })
  }
  totalcountryCount() {
    stakeService.totalcountryCount().then((data) => {
      this.setState({
        totalcountryCount: data.body
      })
    })

  }
  render() {
    const { blockNum, txnNum, totalStaked, totalblocknum,holders, percentage, txTs, txNumber, nodeNum, rametronStake, metatronStake, zetatronStake, totalWallet, TotalSupply, totalcountryCount, totalburnfee, totaltransaction, totalexchanges, totalexhcnageusers, totaldapps } = this.state;
    const { tokenInfo, type, t } = this.props;
    const icon = type + 'wei';
    const token = type.toUpperCase();

    return (
      <React.Fragment>

        <div>

          <div className="explore-1">
            <div className="gif09">
              <div className="">
                <h4 className="topsrc">Pandoproject Network Explorer</h4>
              </div>
              <div className="searchContainer tdcg6">
                <div className="search-select">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg>
                  <select ref={option => this.searchType = option} onChange={(e) => this.clearSearchBox()}  >
                    {/* <option value="">All Filters</option> */}
                    <option value="address">{t('ADDRESS')}</option>
                    <option value="block">{t('BLOCK_HEIGHT')}</option>
                    <option value="transaction">{t('TRANSACTION')}</option>
                  </select>

                </div>
                <div className="searchiconwrapper">
                  <input type="text" className="search-input nwe1" placeholder="Search  by Address, Block Height, Transaction" ref={input => this.searchInput = input} onKeyPress={e => this.handleEnterKey(e)} />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" onClick={e => this.handleSearch()}>
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </div>

                <div className="cduj98-gif">
                  <img src="/images/new-design/Rectangle 9985.gif" alt="" />
                </div>

              </div>
            </div>
          </div>
          {this.state.loading ? (
            <LoadingPanel />
          ) : (
            <div className="demgraph newdemo1">

              <div className={cx("token dashboard newdashh", type)}>

                {/* demo for more less */}

                <div class="far4">

                  <div className="row fornew3">
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon ">
                            <img src="./images/new-design/Layer_1.svg" />
                          </div>
                          <Detail title={`${t('PTX_PRICE')}`} value={'$' + this.state.lbankPrince} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Vector.svg" />
                          </div>
                          <Detail title={`${t(`HR_BLOCKS`)}`} value={formatNumber(blockNum)} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Vector (1).svg" />
                          </div>
                          <Detail title={`${t(`MARKET_CAP`)}`} value={'$' + formatNumber(((Number(TotalSupply) / 1000000000000000000) * this.state.lbankPrince))} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      {/* chart section */}
                      <div className={"chart-container homechart "}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="title"> <img style={{ paddingRight: '11px' }} src="./images/PTX LOGO.svg" alt="" />{t('PTX_TRANSACTION_HISTORY')}</div>
                            <PandoChart chartType={'line'} labels={txTs} data={txNumber} clickType={''} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Group.svg" />
                          </div>
                          <Detail title={`${t('Total Wallets')}`} value={formatNumber(totalWallet)} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Group 2376.svg" />
                          </div>
                          <Detail title={`${t(`TOTAL_SUPPLY`)}`} value={formatNumber(Number(1500000000))} />
                        </div>
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Vector (1).svg" />
                          </div>
                          <div class="detail"><div class="title">TOTAL DAPPS</div><div class="value">6</div></div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="row fornew3 colju">
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Group 24241.svg" />
                          </div>
                          <div class="detail"><div class="title">Blocks </div><div class="value">{formatNumber(totalblocknum)}</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Vector5.svg" />
                          </div>
                          <div class="detail"><div class="title">Transactions</div><div class="value">{formatNumber(totaltransaction)}</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Group 2369.svg" />
                          </div>
                          <Detail title={`${t('Total Countries')}`} value={totalcountryCount.length} />
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-3">
                      <div className="column">
                        <div className="kiimcm">
                          <div className="side-icon">
                            <img src="./images/new-design/Total Stake percent.svg" />
                          </div>
                          <Detail title={`${t('META_ZYTA_TOTAL_STAKED')}`} value={<StakedPercent staked={totalStaked} totalSupply={Number((TotalSupply) / 1000000000000000000) + totalStaked} />} />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div id="collapse-1"  >
                    <div className="row fornew3 colju">
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon">
                              <img src="./images/new-design/Group 2420.svg" />
                            </div>
                            <Detail title={`${t(`Burned PTX`)}`} value={formatNumber((totalburnfee +200000000000000000000000)/ 1000000000000000000)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon">
                              <img src="./images/new-design/Group 1000001072.svg" />
                            </div>
                            <Detail title={`${t('HR_TRANSACTIONS')}`} value={<TxnNumber num={txnNum} />} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon">
                              <img src="./images/new-design/Group 2422.svg" />
                            </div>
                            <Detail title={`${t('CIRCULATING SUPPLY')}`} value={(formatNumber(Number(TotalSupply) / 1000000000000000000))} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="column">
                          <div className="kiimcm">
                            <div className="side-icon">
                              <img src="./images/new-design/Layer 3.svg" />
                            </div>
                            <div class="detail"><div class="title">EXCHANGES</div><div class="value">{totalexchanges}</div></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

            

              </div>

            </div>
          )}

        </div>


        {/* new Rametron section */}


        {/* <div className="dasa">
          <h4>{`${t(`Dapps on Pando Network`)}`}</h4>
          <div className="dappsonpando">
            <div className="wrpas">
              <div >
                <img src="./images/PANDOJO 720x960.png" />
               
                <p>{`${t(`Pandojo is an engaging livestreaming decentralized Web3 application where anyone can go live with just click of a button`)}`}</p>
                <a href="https://pandoproject.org/pandojo/" target="_blank">{`${t(`Learn More`)}`}</a>
              </div>
            </div>
            <div className="wrpas">
              <div >
                <img src="./images/TutorX Logo FINAL copy.png" />
               
                <p>{`${t(`TutorX is a Web3 one-stop education hub for students, teachers and institutes to create, engage and manage classes and track their performance`)}`}</p>
                <a href="https://pandoproject.org/tutorx/" target="_blank">{`${t(`Learn More`)}`}</a>
              </div>
            </div>

            <div className="wrpas">
              <div >
                <img src="./images/logo.png" />
               
                <p>{`${t(`A Web3 based Workplace for professionals to find talent and get client`)}`}</p>
                <a href="https://uniblok.io/" target="_blank">{`${t(`Learn More`)}`}</a>
              </div>
            </div>

            <div className="wrpas">
              <div >
                <img src="./images/Pando_logo.png" />
               
                <p>{`${t(`The blockchain-powered Web3 marketplace with crypto-based payments`)}`}</p>
                <a href="https://pandoproject.org/pando-store/" target="_blank">{`${t(`Learn More`)}`}</a>
              </div>
            </div>

            <div className="wrpas">
              <div >
                <img src="./images/VIXO LOGO 2.png" />
                
                <p>{`${t(`Vixo is a VR-concert platform with in-app ticketing, texting and wallet for an all-in-one immersive experience for concert-goers and performers`)}`}</p>
                <a href="https://pandoproject.org/vixo/" target="_blank">{`${t(`Learn More`)}`}</a>
              </div>
            </div>


          </div>
          <a className="viewmore" href="https://pandoproject.org/creators/" target="_blank">{`${t(`View More`)}`}</a>
        </div> 
 */}










      </React.Fragment>
    );
  }
}

const Detail = ({ title, value }) => {
  return (
    <div className="detail">
      <div className="title">{title}</div>
      <div className={cx("value", { price: title.includes('Price') })}>{value}</div>
    </div>
  );
}

const TxnNumber = ({ num }) => {
  const duration = 24 * 60 * 60;
  const tps = num / duration;
  return (
    <React.Fragment>
      {`${formatNumber(num)}`}
      {/* <div className="tps">[{tps.toFixed(2)} TPS]</div> */}
    </React.Fragment>
  );
}

const StakedPercent = ({ staked, totalSupply }) => {
  return (
    <React.Fragment>
      {`${new BigNumber(staked).dividedBy(totalSupply / 100).toFixed(2)}%`}
    </React.Fragment>
  );
}